import { makeAutoObservable, reaction } from "mobx";

import { CalcEffectTableNode, TableRow } from "../calcEffectTableModel";
import { CalcModel } from "../calcModel";

class CalcModelMiningWells {
  public wellsAmount: TableRow;
  public gtmAmount: TableRow;
  public additionalMining: TableRow;
  public transferringMining: TableRow;
  public serviceCoefficient: TableRow;
  constructor(public node: CalcEffectTableNode, public calcModel: CalcModel) {
    makeAutoObservable(this);
    this.wellsAmount = Array.from(node.children ?? []).find(
      (child) => child.rowInfo.title === "Количество скважин базового фонда"
    )!;
    this.gtmAmount = Array.from(node.children!).find((child) => child.rowInfo.title === "Количество ГТМ")!;
    this.additionalMining = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Доп. добыча за год проведения ГТМ"
    )!;
    this.transferringMining = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Переходящая доп. добыча от ГТМ"
    )!;
    this.serviceCoefficient = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Коэффициент эксплуатации скважины"
    )!;

    reaction(
      () => [
        ...this.calcModel.similarParamsModel.map((param) => Object.values(param.gtmAmount.data)),
        ...Object.values(
          this.calcModel.otherGTMModel.summary.find((param) => param.rowInfo.title === "Количество ГТМ")?.data ?? []
        ),
      ],
      () => {
        Object.keys(this.gtmAmount.data).forEach((year) => {
          if (this.calcModel.similarParamsModel.filter((param) => param.gtmAmount.data[+year] !== null).length > 0) {
            let newValue = this.calcModel.similarParamsModel
              .filter((param) => param.gtmAmount.data[+year] !== null)
              .reduce((prev, curr) => prev + curr.gtmAmount.data[+year]!, 0);
            if (
              this.calcModel.otherGTMModel.summary.find((param) => param.rowInfo.title === "Количество ГТМ")?.data[
                +year
              ] !== null
            ) {
              newValue += this.calcModel.otherGTMModel.summary.find((param) => param.rowInfo.title === "Количество ГТМ")
                ?.data[+year]!;
            }

            this.gtmAmount.mutationsManager?.updateWrapper(+year, newValue);
          }
        });
      }
    );

    reaction(
      () => [
        ...this.calcModel.similarParamsModel.map((param) => Object.values(param.additionalMining.data)),
        ...Object.values(
          this.calcModel.otherGTMModel.summary.find(
            (param) => param.rowInfo.title === "Доп. добыча за год проведения ГТМ"
          )?.data ?? []
        ),
      ],
      () => {
        Object.keys(this.additionalMining.data).forEach((year) => {
          if (
            this.calcModel.similarParamsModel.filter((param) => param.additionalMining.data[+year] !== null).length > 0
          ) {
            let newValue = this.calcModel.similarParamsModel
              .filter((param) => param.additionalMining.data[+year] !== null)
              .reduce((prev, curr) => prev + curr.additionalMining.data[+year]!, 0);
            if (
              this.calcModel.otherGTMModel.summary.find(
                (param) => param.rowInfo.title === "Доп. добыча за год проведения ГТМ"
              )?.data[+year] !== null
            ) {
              newValue += this.calcModel.otherGTMModel.summary.find(
                (param) => param.rowInfo.title === "Доп. добыча за год проведения ГТМ"
              )?.data[+year]!;
            }

            this.additionalMining.mutationsManager?.updateWrapper(+year, newValue);
          }
        });
      }
    );

    reaction(
      () => [
        ...this.calcModel.similarParamsModel.map((param) => Object.values(param.transferringMining.data)),
        ...Object.values(
          this.calcModel.otherGTMModel.summary.find((param) => param.rowInfo.title === "Переходящая доп. добыча от ГТМ")
            ?.data ?? []
        ),
      ],
      () => {
        Object.keys(this.transferringMining.data).forEach((year) => {
          if (
            this.calcModel.similarParamsModel.filter((param) => param.transferringMining.data[+year] !== null).length >
            0
          ) {
            let newValue = this.calcModel.similarParamsModel
              .filter((param) => param.transferringMining.data[+year] !== null)
              .reduce((prev, curr) => prev + curr.transferringMining.data[+year]!, 0);
            if (
              this.calcModel.otherGTMModel.summary.find(
                (param) => param.rowInfo.title === "Переходящая доп. добыча от ГТМ"
              )?.data[+year] !== null
            ) {
              newValue += this.calcModel.otherGTMModel.summary.find(
                (param) => param.rowInfo.title === "Переходящая доп. добыча от ГТМ"
              )?.data[+year]!;
            }

            this.transferringMining.mutationsManager?.updateWrapper(+year, newValue);
          }
        });
      }
    );

    reaction(
      () => this.calcModel.getFirstNElements(this.serviceCoefficient, 1),
      (first) => {
        Object.keys(this.serviceCoefficient.data).forEach((year, index) => {
          if (index > 0 && first[0] !== null) {
            this.serviceCoefficient.mutationsManager?.updateWrapper(+year, first[0]);
          }
        });
      }
    );
  }
}

export { CalcModelMiningWells };
